import React, { Component } from 'react';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="p-20 container">
        <h1 className="text-2xl font-bold mb-4">Datenschutzerklärung</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1) Erklärung zur Informationspflicht</h2>
          <p>
            Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2) Kontakt mit uns</h2>
          <p>
            Wenn Sie per Formular auf der Website oder per E-Mail-Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3) Cookies</h2>
          <p>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
          </p>
          <p className="mt-4">
            Wir verwenden folgende Cookies auf unserer Seite:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>Cookies: _ga, _gid, _gat_gtag_UA_109835362_1</li>
            <li>Beschreibung: Sammelt anonymisierte Statistik Daten die uns helfen unsere Seite zu verbessern. Es werden keine personenspezifischen Daten gesammelt.</li>
            <li>Cookies: gdpr[allowed_cookies], gdpr[consent_types], gdpr%5Bprivacy_bar%5D</li>
            <li>Beschreibung: Dient der Benachrichtigung des Users über unseren Datenschutz und Cookie Richtlinien auf unserer Seite.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4) Web-Analyse</h2>
          <p>
            Unsere Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“ (Siehe dritten Absatz der Datenschutzerklärung). Im Falle der Aktivierung der IP-Anonymisierung auf unserer Webseite, wird deine IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers unserer Website wird Google diese Informationen benutzen, um deine Nutzung unserer Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber IdlibCom zu erbringen.
          </p>
          <p>
            Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern, wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout" className="text-blue-500 underline">Browser-Add-on zur Deaktivierung von Google Analytics</a>
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5) Ihre Rechte</h2>
          <p>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie einen Antrag zur Löschung Ihrer personenbezogenen Daten an uns stellen. Gleiches gilt für Auskünfte, Sperrung, Löschungs- und Berichtigungswünsche hinsichtlich Ihrer personenbezogenen Daten sowie für Widerrufe erteilter Einwilligungen.
          </p>
          <p>
            Sie erreichen uns unter folgenden Kontaktdaten:
          </p>
          <address className="mt-4 not-italic">
            <p>Adresse: Favoriten Straße 61/1, 1100 Wien Österreich</p>
            <p>Telefon: 067 66 999 888</p>
            <p>E-Mail: <a href="mailto:info@idlibcom.at" className="text-blue-500 underline">info@idlibcom.at</a></p>
          </address>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;
